import 'babel-polyfill';
import 'whatwg-fetch';
import 'es6-promise/auto';
import Vue from 'vue';
import VModal from 'vue-js-modal';
import VeeValidator from 'vee-validate';
import ko from 'vee-validate/dist/locale/ko.js';
import * as filters from '@/filters';

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import VueDragscroll from 'vue-dragscroll';

import CommonUtils from '@/utils/common';

import App from './App.vue';
import router from './router';
import store from './store';

Vue.config.productionTip = false;
Vue.use(VModal);

Vue.use(Loading, {
  color: 'blue',
});

Vue.use(VueDragscroll);

Vue.use(CommonUtils);

const config = {
  locale: 'ko',
  dictionary: {
    ko,
  },
};
Vue.use(VeeValidator, config);

Object.keys(filters).forEach((key) => {
  Vue.filter(key, (filters as { [key: string ]: Function })[key]);
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
