import cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import moment from 'moment';

// User
const tokenKey = 'access_token';
export const getTokenDecode = () => jwtDecode(cookies.get(tokenKey) || '');
export const getToken = () => cookies.get(tokenKey);
export const setToken = (token: string) => cookies.set(tokenKey, token);
export const removeToken = () => cookies.remove(tokenKey);

const visitKey = `visitHistory${moment().format('YYYYMMDD')}`;
export const getVisitKey = () => cookies.get(visitKey);
export const setVisitKey = () => cookies.set(visitKey, '1', { expires: 1 });
