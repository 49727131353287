
import { Vue, Component, Prop } from 'vue-property-decorator';
import $ from 'jquery';

@Component({
  name: 'SubFooter',
})
export default class extends Vue {
  @Prop({ required: true }) private mainViewIdList!: []

  private handleClickFamilySite() {
    ($('.ft_site') as any).slideToggle({ direction: 'up' }, 500);
    $('#ft_BtnSite > span').toggleClass('on');
  }

  private scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
