
import { Vue, Component } from 'vue-property-decorator';
import { getHomepageDetail } from '@/api/myHomepage';
import { getBoardList } from '@/api/myHomepageBoard';
import { getHomepageVisitCount } from '@/api/myHomepageVisit';
import { IMyHomepageBoard, IMyHomepageDetail } from '@/types';
import { HomepageBody } from './components/homepage';
import {
  HomepageHeader1,
  HomepageFooter1,
} from './components/homepage/type1';
import {
  HomepageHeader2,
  HomepageFooter2,
} from './components/homepage/type2';
import {
  HomepageHeader3,
  HomepageFooter3,
} from './components/homepage/type3';

@Component({
  name: 'SubLayout',
  components: {
    HomepageBody,
    HomepageHeader1,
    HomepageFooter1,
    HomepageHeader2,
    HomepageFooter2,
    HomepageHeader3,
    HomepageFooter3,
  },
})
export default class extends Vue {
  mounted() {
    getHomepageDetail(this.$route.params.nickname).then((res) => {
      this.homepageDetail = res.data;
    });
    getBoardList(this.$route.params.nickname).then((res) => {
      this.homepageBoardList = res.data;
    });
    getHomepageVisitCount(this.$route.params.nickname).then((res) => {
      this.homepageVisitCount = res.data;
    });
  }

  private homepageDetail: IMyHomepageDetail | null = null;

  private homepageBoardList: IMyHomepageBoard[] = [];

  private homepageVisitCount: any = {
    todayCount: 0,
    totalCount: 0,
  };
}
