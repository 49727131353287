
import { FcmTokenModule } from '@/store/fcm-token';
import { Vue, Component } from 'vue-property-decorator';
import { AppHeader, AppBody, AppFooter } from './components/app';

@Component({
  name: 'AppLayout',
  components: {
    AppHeader,
    AppBody,
    AppFooter,
  },
})
export default class extends Vue {
  mounted() {
    document.getElementsByTagName('html')[0].classList.add('noscr');
    (window as any).getFcmToken = (token: string) => {
      FcmTokenModule.setToken(token);
    };
    (window as any).fcmToken.postMessage('');
  }
}
