
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'BoardPagination',
})
export default class extends Vue {
  @Prop({ required: true }) private listQuery!: any

  @Prop({ required: true }) private listTotal!: number

  @Prop({ required: true }) private totalPages!: number

  private getStartPage() {
    return Math.floor(this.listQuery.page / 10) * 10 + 1;
  }

  private getEndPage() {
    if (this.totalPages === 0) return 1;
    if (this.getStartPage() + 9 < this.totalPages) return this.getStartPage() + 9;
    return this.totalPages;
  }

  private getEndRange() {
    if (this.getEndPage() < 11 && this.totalPages > 10) return 10;
    const endRange = this.getEndPage() % 10;
    if (endRange === 0) return 10;
    return endRange;
  }

  private handleClickPage(page: number) {
    this.$emit('change', page - 1);
  }

  private handleClickPrev() {
    if (this.getStartPage() === 1) this.$emit('change', 0);
    else this.$emit('change', this.getStartPage() - 2);
  }

  private handleClickNext() {
    if (this.getEndPage() !== this.totalPages) this.$emit('change', this.getEndPage());
    else this.$emit('change', this.totalPages - 1);
  }
}
