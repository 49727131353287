
import { IMyHomepageDetail } from '@/types';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'HomepageFooter1',
})
export default class extends Vue {
  @Prop({ required: true }) private detail!: IMyHomepageDetail

  get imageUrl() {
    return `${process.env.VUE_APP_COMMON_API}/attached-file`;
  }
}
