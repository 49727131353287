
import { Vue, Component, Watch } from 'vue-property-decorator';
import { getMenu } from '@/api/menu';
import { IMenuDetail } from '@/types';

@Component({
  name: 'SubBody',
})
export default class extends Vue {
  @Watch('$route')
  private handleChangeRoute() {
    this.getMenuDetail();
  }

  mounted() {
    this.getMenuDetail();
  }

  private menu: IMenuDetail | null = null;

  private parentMenu: IMenuDetail | null = null;

  private grandMenu: IMenuDetail | null = null;

  private getMenuDetail() {
    if (this.$route.params.menuUid) {
      getMenu(this.$route.params.menuUid).then((res) => {
        this.menu = res.data;
        if (res.data.children.length > 0) {
          if (res.data.menuType === 'BOARD') this.$router.push({ name: 'BoardIndex', params: { menuUid: res.data.children[0].uid } });
          if (res.data.menuType === 'CONTENTS') this.$router.push({ name: 'ContentsIndex', params: { menuUid: res.data.children[0].uid } });
          if (res.data.menuType === 'MODULE') this.$router.push({ name: res.data.moduleUid, params: { menuUid: res.data.children[0].uid } });
        }
        if (res.data.parentUid) {
          getMenu(res.data.parentUid).then((response) => {
            this.parentMenu = response.data;
            if (response.data.parentUid) {
              getMenu(response.data.parentUid).then((response2) => {
                this.grandMenu = response2.data;
              });
            } else {
              this.grandMenu = null;
            }
          });
        } else {
          this.parentMenu = null;
        }
      });
    } else {
      this.menu = null;
      this.parentMenu = null;
      this.grandMenu = null;
    }
  }

  private getRouter(menu: any) {
    const router = {
      name: '',
      params: { menuUid: menu.uid },
    };
    if (menu.menuType === 'BOARD') router.name = 'BoardIndex';
    if (menu.menuType === 'CONTENTS') router.name = 'ContentsIndex';
    if (menu.menuType === 'MODULE') router.name = menu.moduleUid;
    return router;
  }

  private getActiveRouter(menuUid: string) {
    return this.$route.params.menuUid === menuUid;
  }

  private getLink(link: string) {
    if (link) {
      if (!link.startsWith('http')) return `http://${link}`;
      return link;
    }
    return '#';
  }
}
