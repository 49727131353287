
import { UserModule } from '@/store/user';
import { IMyHomepageBoard, IMyHomepageDetail } from '@/types';
import { Vue, Component, Prop } from 'vue-property-decorator';
import HomepageVisit from '@/components/homepage/visit.vue';
import MyBlog from '@/components/blog/index.vue';

@Component({
  name: 'HomepageHeader3',
  components: {
    HomepageVisit,
    MyBlog,
  },
})
export default class extends Vue {
  @Prop({ required: true }) private detail!: IMyHomepageDetail

  @Prop({ required: true }) private boardList!: IMyHomepageBoard[]

  @Prop({ required: true }) private visitCount!: any

  get imageUrl() {
    return `${process.env.VUE_APP_COMMON_API}/attached-file`;
  }

  get isLogined() {
    return UserModule.token;
  }

  get isAdmin() {
    return UserModule.userId === this.detail.userDetail.userId;
  }

  private isActive = false;

  private visitModalVisible = false;

  private blogVisible = false;

  private blogNickName = '';

  private handleClickOpenMenu() {
    this.isActive = !this.isActive;
  }

  private handleClickLogout() {
    this.isActive = false;
    UserModule.LogOut();
  }

  private handleVisitModal() {
    this.visitModalVisible = !this.visitModalVisible;
  }

  private handleVisibleBlog(nickName: string) {
    this.blogVisible = !this.blogVisible;
    this.blogNickName = nickName;
  }
}
