import request from '@/utils/request';

const PATH = '/search';

export const search = (searchDto: any) => request({
  url: PATH,
  method: 'get',
  params: {
    ...searchDto,
  },
});

export const searchUser = (searchDto: any) => request({
  url: `${PATH}/user`,
  method: 'get',
  params: {
    ...searchDto,
  },
});

export const getSearchDataList = () => request({
  url: `${PATH}/data`,
  method: 'get',
});
