
import {
  Vue,
  Component,
  Prop,
  Watch,
} from 'vue-property-decorator';
import $ from 'jquery';
import { UserModule } from '@/store/user';
import { ISearchData, IMenuDetail } from '@/types';
import { getSearchDataList } from '@/api/search';
import { getInfo } from '@/api/user';
import MyBlog from '@/components/blog/index.vue';
import { getMenu } from '@/api/menu';
import { getToken } from '@/utils/cookies';

@Component({
  name: 'MainHeader',
  components: {
    MyBlog,
  },
})
export default class extends Vue {
  @Prop({ required: true }) private menuList!: [];

  @Watch('$route')
  private handleChangeRouter() {
    this.siteMapActive = false;
    this.hamburgerActive = false;
  }

  private handleChangeRoute() {
    this.getMenuDetail();
  }

  toggleHeaderActive() {
    this.headerActive = this.headerActive === 1 ? 0 : 1;
  }

  mounted() {
    console.log(getToken());
    getInfo().then((res) => {
      this.info = res.data;
    });
    getSearchDataList().then((res) => {
      this.searchDataList = res.data;
    });
    setInterval(() => {
      this.tick();
    }, 5000);
    this.getMenuDetail();
  }

  private isLogined() {
    if (getToken() === undefined) {
      return true;
    }
    return false;
  }

  private isLogin = false;

  private quickActive = false;

  private scrollTop = 0;

  private headerActive = 0;

  private gnbActiveIndex = -1;

  private siteMapActive = false;

  private hamburgerActive = false;

  private searchValue = '';

  private searchDataList: ISearchData[] = [];

  private menu: IMenuDetail | null = null;

  private info = {
    nickName: '',
    homepageState: false,
    blogState: false,
  };

  private blogVisible = false;

  private getMenuDetail() {
    if (this.$route.params.menuUid) {
      getMenu(this.$route.params.menuUid).then((res) => {
        this.menu = res.data;
        if (res.data.children.length > 0) {
          if (res.data.menuType === 'BOARD') {
            this.$router.push({ name: 'BoardIndex', params: { menuUid: res.data.children[0].uid } });
          }
          if (res.data.menuType === 'CONTENTS') {
            this.$router.push({ name: 'ContentsIndex', params: { menuUid: res.data.children[0].uid } });
          }
          if (res.data.menuType === 'MODULE') {
            this.$router.push({ name: res.data.moduleUid, params: { menuUid: res.data.children[0].uid } });
          }
        }
      });
    } else {
      this.menu = null;
    }
  }

  private getRouter(menu: any) {
    const router = {
      name: '',
      params: { menuUid: menu.uid },
    };
    if (menu.menuType === 'BOARD') router.name = 'BoardIndex';
    if (menu.menuType === 'CONTENTS') router.name = 'ContentsIndex';
    if (menu.menuType === 'MODULE') {
      router.name = menu.moduleUid;
    }
    return router;
  }

  private getActiveRouter(menuUid: string) {
    return this.$route.params.menuUid === menuUid;
  }

  private getLink(link: string) {
    if (link) {
      if (!link.startsWith('http')) return `http://${link}`;
      return link;
    }
    return '#';
  }

  private handleClickHamburger() {
    this.siteMapActive = !this.siteMapActive;
    this.hamburgerActive = !this.hamburgerActive;
  }

  private tick() {
    $('.popular_inner li:first').slideUp(() => {
      $('.popular_inner li:first').appendTo($('.popular_inner ul')).slideDown();
    });
  }

  private handleClickNavi(type: string) {
    if (type === 'prev') {
      $('.popular_inner li:last')
        .hide()
        .prependTo($('.popular_inner ul'))
        .slideDown();
    }
    if (type === 'next') {
      $('.popular_inner li:first').slideUp(() => {
        $('.popular_inner li:first').detach().appendTo('.popular_inner ul');
      });
    }
  }

  private async handleClickLogout() {
    await UserModule.LogOut();
    this.isLogin = false;
    this.siteMapActive = false;
    this.hamburgerActive = false;
    this.$router.go(0);
  }

  private handleClickMenu() {
    this.siteMapActive = !this.siteMapActive;
    this.hamburgerActive = !this.hamburgerActive;
  }

  private handleSearch() {
    if (this.searchValue.trim()) {
      this.$router.push({
        name: 'Search',
        query: { searchValue: this.searchValue },
      });
    } else {
      alert('검색어를 입력해주세요.');
    }
  }

  private handleClickHomepage() {
    if (this.info.nickName) {
      const routeData = this.$router.resolve({
        name: 'HomepageIndex',
        params: { nickname: this.info.nickName },
      });
      window.open(routeData.href, '_blank');
    }
  }

  private handleClickBlog() {
    this.blogVisible = !this.blogVisible;
  }

  private handleScroll(event: any) {
    this.scrollTop = event.currentTarget.scrollTop;
  }
}
