
import { IMyHomepageBoard, IMyHomepageDetail } from '@/types';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'HomepageBody',
})
export default class extends Vue {
  @Prop({ required: true }) private detail!: IMyHomepageDetail

  @Prop({ required: true }) private boardList!: IMyHomepageBoard[]
}
