import request from '@/utils/request';

const PATH = '/my-homepage/visit';

export const getHomepageVisitList = (listQuery: any) => request({
  url: PATH,
  method: 'get',
  params: {
    ...listQuery,
  },
});

export const getHomepageVisitCount = (domain: string) => request({
  url: `${PATH}/${domain}`,
  method: 'get',
});
