
import {
  Vue,
  Component,
  Watch,
} from 'vue-property-decorator';

@Component({
  name: 'AppBody',
})
export default class extends Vue {
  @Watch('routerName')
  private handleChangeRouterName() {
    (this.$refs.appBody as any).scrollTo(0, 0);
  }

  get routerName() {
    return this.$route.name;
  }
}
