
import { UserModule } from '@/store/user';
import {
  Vue,
  Component,
} from 'vue-property-decorator';
import { getAlarmCount } from '@/api/pushAlarm';

@Component({
  name: 'AppHeader',
})
export default class extends Vue {
  mounted() {
    this.getAlarmCount();
  }

  get routerName() {
    return this.$route.name;
  }

  get currentMenu() {
    let menu = '마이페이지';
    if (this.routerName === 'AppLogin') menu = '로그인';
    else if (this.routerName === 'AppSupportList' || this.routerName === 'AppSupportDetail') menu = '지원사업';
    else if (this.routerName === 'AppEventList' || this.routerName === 'AppEventDetail') menu = '행사정보';
    else if (this.routerName === 'AppUserJoin' || this.routerName === 'AppJoinTerms') menu = '회원가입';
    else if (this.routerName === 'AppFindPassword') menu = '비밀번호 재설정';
    else if (this.routerName === 'AppNoticeDetail') menu = '공지사항';
    else if (this.routerName === 'AppAgency') menu = '창업입주시설';
    else if (this.routerName === 'AppMakerspace') menu = '메이커스페이스';
    else if (this.routerName === 'AppMapView') menu = '창업지도검색';
    else if (this.routerName === 'AppEquipment') menu = '장비지원';
    else if (this.$route.params.menuUid === 'efaab57c-d30f-44de-8a58-175d8f78dffa') menu = '멘토링분야';
    else if (this.$route.params.menuUid === 'dd8bb6d3-94fd-4171-ba7e-e01cdb71255f') menu = '멘토 소개';
    else if (this.$route.params.menuUid === 'be4a4a8b-ccb4-4338-a7df-2925ff5f5a44') menu = '상담 신청';
    else if (this.routerName === 'AppAlarm') menu = '알람 목록';
    return menu;
  }

  get isLogined() {
    return UserModule.token;
  }

  private alarmCount = 0;

  private menuActive = false;

  private handleClickHamburger() {
    this.menuActive = !this.menuActive;
  }

  private getAlarmCount() {
    if (this.isLogined) {
      getAlarmCount().then((res) => {
        this.alarmCount = res.data;
      });
    }
  }
}
