import request from '@/utils/request';

const PATH = '/menu';

export const getMenuList = () => request({
  url: PATH,
  method: 'get',
});

export const getMainMenuList = () => request({
  url: `${PATH}/mainShow`,
  method: 'get',
});

export const getUidList = () => request({
  url: `${PATH}/uidList`,
  method: 'get',
});

export const getMainViewIds = () => request({
  url: `${PATH}/main`,
  method: 'get',
});

export const getSearchMenuList = () => request({
  url: `${PATH}/search`,
  method: 'get',
});

export const getMenu = (uid: string) => request({
  url: `${PATH}/${uid}`,
  method: 'get',
});
