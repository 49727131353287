import { IMyHompageIntroduceSave } from '@/types';
import request from '@/utils/request';

const PATH = '/my-homepage/board';

export const getBoardList = (nickname: string) => request({
  url: `${PATH}/${nickname}/list`,
  method: 'get',
});

export const getBoard = (uid: string) => request({
  url: `${PATH}/${uid}`,
  method: 'get',
});
