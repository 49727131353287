import {
  VuexModule,
  Module,
  Action,
  Mutation,
  getModule,
} from 'vuex-module-decorators';
import { addVisit, checkToken, login } from '@/api/user';
import {
  getToken, getTokenDecode, setToken, removeToken,
} from '@/utils/cookies';
import store from '@/store';
import { getRoleList } from '@/api/role';

export interface IUserState {
  token: string
  userId: string
  roles: string[]
  errorMessage: string
}

@Module({ dynamic: true, store, name: 'user' })
class User extends VuexModule implements IUserState {
  public token = getToken() || ''

  public userId = ''

  public userUid = ''

  public roles: string[] = []

  public errorMessage = ''

  @Mutation
  private SET_TOKEN(token: string) {
    this.token = token;
  }

  @Mutation
  private SET_USER_ID(userId: string) {
    this.userId = userId;
  }

  @Mutation
  private SET_USER_UID(userUid: string) {
    this.userUid = userUid;
  }

  @Mutation
  private SET_ROLES(roles: string[]) {
    this.roles = roles;
  }

  @Mutation
  private SET_ERROR_MESSAGE(errorMessage: string) {
    this.errorMessage = errorMessage;
  }

  @Action
  public async Login(userInfo: { username: string, password: string }) {
    return new Promise((resolve, reject) => {
      const { username, password } = userInfo;
      login({ username, password }).then((res) => {
        setToken(res.data.access_token);
        this.SET_TOKEN(res.data.access_token);
        this.GetUserInfo();
        addVisit();
        resolve('');
      }).catch((error) => {
        if (window.location.pathname === '/app/login') this.SET_ERROR_MESSAGE(error.response.data.error_description);
        else window.alert(error.response.data.error_description);
        reject();
      });
    });
  }

  @Action
  public async CheckToken() {
    return new Promise((resolve, reject) => {
      checkToken().then(() => {
        resolve('');
      }).catch(() => {
        this.LogOut();
        resolve('');
      });
    });
  }

  @Action
  public ResetToken() {
    removeToken();
    this.SET_TOKEN('');
    this.SET_USER_ID('');
    this.SET_ROLES([]);
  }

  @Action
  public async GetUserInfo() {
    if (this.token === '') {
      throw Error('GetUserInfo: token is undefined!');
    }
    // tokenCheck(this.token).catch(() => {
    //   throw Error('Verification failed, please Login again.');
    // });
    const data: any = await getTokenDecode();
    if (!data) {
      throw Error('Verification failed, please Login again.');
    }
    // roles must be a non-empty array
    /*
    if (!data.authorities || data.authorities.length <= 0) {
      alert('권한이 존재하지 않습니다.');
    }
    */
    this.SET_ROLES(data.authorities);
    this.SET_USER_ID(data.user_name);
    this.SET_USER_UID(data.uid);
  }

  @Action
  public async LogOut() {
    if (this.token === '') {
      throw Error('LogOut: token is undefined!');
    }
    removeToken();

    // Reset visited views and cached views
    this.SET_TOKEN('');
    this.SET_ROLES([]);
    this.SET_USER_ID('');
  }

  @Action
  public ResetErrorMessage() {
    this.SET_ERROR_MESSAGE('');
  }
}

export const UserModule = getModule(User);
