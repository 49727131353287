import { IMyHomepageUpdate } from '@/types';
import request from '@/utils/request';

const PATH = '/my-blog';

export const getBlogList = (listQuery: any) => request({
  url: PATH,
  method: 'get',
  params: {
    ...listQuery,
  },
});

export const getBlog = (uid: string) => request({
  url: `${PATH}/${uid}/view`,
  method: 'get',
});

export const getBlogInfo = (domain: string) => request({
  url: `${PATH}/${domain}`,
  method: 'get',
});

export const getBlogVisit = (domain: string) => request({
  url: `${PATH}/${domain}/visit`,
  method: 'get',
});

export const getBlogVisitCount = (domain: string) => request({
  url: `${PATH}/${domain}/visitCount`,
  method: 'get',
});

export const getBlogPostList = (domain: string, boardUid: string, pageable: any) => request({
  url: `${PATH}/${domain}/${boardUid}/post`,
  method: 'get',
  params: {
    ...pageable,
  },
});
