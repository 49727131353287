import request from '@/utils/request';

const PATH = '/business';

export const getBusiness = (idx: any) => request({
  url: `${PATH}/${idx}`,
  method: 'get',
});

export const addBusiness = (data: any) => request({
  url: PATH,
  method: 'post',
  data,
});

export const updateBusiness = (idx: any, data: any) => request({
  url: `${PATH}/${idx}`,
  method: 'put',
  data,
});

export const deleteBusiness = (idx: any) => request({
  url: `${PATH}/${idx}`,
  method: 'delete',
});

export const getBusinessList = (params: any) => request({
  url: PATH,
  method: 'get',
  params,
});

export const downloadExcel = (idx: any, params: any) => request({
  url: `${PATH}/${idx}/excel`,
  method: 'get',
  params,
  responseType: 'blob',
});
