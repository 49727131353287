/* eslint-disable */
import Vue from 'vue';

export default {
	install(Vue: any) {
    Vue.prototype.$toLinkInApp = common.toLinkInApp;
  }
}

export const common = {
  toLinkInApp: (url: string) => {
    if ((window as any).newBrowser) (window as any).newBrowser.postMessage(url);
    else window.open(url);
  }
}