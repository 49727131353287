import { IUserJoin } from '@/types';
import request from '@/utils/request';
import axios from 'axios';
import qs from 'qs';
import { setVisitKey } from '@/utils/cookies';
import { UserModule } from '@/store/user';

const PATH = '/user';

/* eslint-disable */
export const login = (data: any) => {
  data.grant_type = 'password';
  return axios({
    method: 'post',
    url: `${process.env.VUE_APP_OAUTH_API}/token`,
    data: qs.stringify(data),
    headers: {
      Authorization: 'Basic c2luZ2hhX29hdXRoOnNpbmdoYXNjcmVjdCFAIyQ=',
    },
  });
};
/* eslint-enable */

/* eslint-disable */
export const checkToken = () => {
  const data = {
    token: UserModule.token,
  };
  return axios({
    method: 'post',
    url: `${process.env.VUE_APP_OAUTH_API}/check_token`,
    headers: {
      Authorization: 'Basic c2luZ2hhX29hdXRoOnNpbmdoYXNjcmVjdCFAIyQ=',
    },
    data: qs.stringify(data),
  });
};
/* eslint-enable */

export const addVisitHistory = (ipAddress: string) => request({
  url: '/visit-history',
  method: 'post',
  params: {
    ip: ipAddress,
  },
});

export const getUserInfo = () => request({
  url: PATH,
  method: 'get',
});

export const getAgencyList = (listQuery: any) => request({
  url: `${PATH}/agency`,
  method: 'get',
  params: {
    ...listQuery,
  },
});

export const getRelationList = (params: any) => request({
  url: `${PATH}/relation`,
  method: 'get',
  params: {
    ...params,
  },
});

export const checkUserId = (userId: string) => request({
  url: `${PATH}/id-check/${userId}`,
  method: 'get',
});

export const checkNickName = (nickName: string) => request({
  url: `${PATH}/nickName-check/${nickName}`,
  method: 'get',
});

export const checkPhone = (phone: string) => request({
  url: `${PATH}/phone-check/${phone}`,
  method: 'get',
});

export const getInfo = () => request({
  url: `${PATH}/info`,
  method: 'get',
});

export const joinUser = (data: IUserJoin) => request({
  url: PATH,
  method: 'post',
  data,
});

export const findPassword = (data: any) => request({
  url: `${PATH}/find-password`,
  method: 'post',
  data,
});

export const updateUser = (data: IUserJoin) => request({
  url: PATH,
  method: 'put',
  data,
});

export const updatePasswordUser = (data: IUserJoin) => request({
  url: `${PATH}/password`,
  method: 'put',
  data,
});

export const addVisit = () => {
  axios.get('https://api.ipify.org?format=json').then((res) => {
    addVisitHistory(res.data.ip).then(() => {
      setVisitKey();
    });
  });
};
