import {
  VuexModule,
  Module,
  Action,
  Mutation,
  getModule,
} from 'vuex-module-decorators';
import store from '@/store';

export interface FcmTokenState {
  fcmToken: string
}

@Module({ dynamic: true, store, name: 'fcmToken' })
class FcmToken extends VuexModule implements FcmTokenState {
  public fcmToken = ''

  @Mutation
  private SET_FCM_TOKEN(token: string) {
    this.fcmToken = token;
  }

  @Action
  public setToken(token: string) {
    this.SET_FCM_TOKEN(token);
  }
}

export const FcmTokenModule = getModule(FcmToken);
