
import { Vue, Component } from 'vue-property-decorator';
import moment from 'moment';
import { getHomepageVisitList } from '@/api/myHomepageVisit';
import { IMyHomepageVisit } from '@/types';
import Pagination from '@/components/board/pagination.vue';

@Component({
  name: 'HomepageVisit',
  components: {
    Pagination,
  },
})
export default class extends Vue {
  mounted() {
    this.getVisitList();
  }

  private visitList: IMyHomepageVisit[] = [];

  private listQuery = {
    page: 0,
    size: 10,
    startDate: moment().add(-6, 'day').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  };

  private listTotal = 0;

  private totalPages = 0;

  private handleClickClose() {
    this.$emit('close');
  }

  private getVisitList() {
    getHomepageVisitList(this.listQuery).then((res) => {
      this.visitList = res.data.content;
      this.listTotal = res.data.totalElements;
      this.totalPages = res.data.totalPages;
    });
  }

  private handleSearch() {
    this.listQuery.page = 0;
    this.getVisitList();
  }

  private handleChangePaging(page: number) {
    this.listQuery.page = page;
    this.getVisitList();
  }

  private handleVisit(visit: IMyHomepageVisit) {
    if (visit.user) {
      const routeData = this.$router.resolve({ name: 'HomepageIndex', params: { nickname: visit.nickName } });
      window.open(routeData.href, '_blank');
    } else {
      this.handleClickClose();
      this.$emit('openBlog', visit.nickName);
    }
  }
}
