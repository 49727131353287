import { IMyHomepageUpdate } from '@/types';
import request from '@/utils/request';

const PATH = '/my-homepage';

export const getHomepageList = (listQuery: any) => request({
  url: PATH,
  method: 'get',
  params: {
    ...listQuery,
  },
});

export const getMainList = () => request({
  url: `${PATH}/main`,
  method: 'get',
});

export const getHomepageInfo = (nickname: string) => request({
  url: `${PATH}/${nickname}/info`,
  method: 'get',
});

export const getHomepageDetail = (nickname: string) => request({
  url: `${PATH}/${nickname}`,
  method: 'get',
});

export const updateHomepage = (data: IMyHomepageUpdate) => request({
  url: PATH,
  method: 'put',
  data,
});
