import Vue from 'vue';
import VueRouter, { Route, RouteConfig } from 'vue-router';

import MainLayout from '@/layout/main.vue';
import SubLayout from '@/layout/sub.vue';
import HomepageLayout from '@/layout/homepage.vue';
import AppLayout from '@/layout/app.vue';
import { UserModule } from '@/store/user';
import { NiceModule } from '@/store/nice';
import { addReferrerHistory } from '@/api/referrerHistory';
import { component } from 'vue/types/umd';
import axios from 'axios';

import { addVisit, addVisitHistory } from '@/api/user';
import { getVisitKey, setVisitKey } from '@/utils/cookies';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Index',
    component: MainLayout,
  },
  {
    path: '/open-api-form',
    component: () => import('@/views/document/form.vue'),
    name: 'OpenApiForm',
  },
  {
    path: '/sub',
    name: 'Sub',
    component: SubLayout,
    children: [
      {
        path: '/contents/:menuUid',
        component: () => import('@/views/sub/contents.vue'),
        name: 'ContentsIndex',
      },
      {
        path: '/board/:menuUid',
        component: () => import('@/views/sub/board/index.vue'),
        name: 'BoardIndex',
      },
      {
        path: '/post/:menuUid/add',
        component: () => import('@/views/sub/board/form.vue'),
        name: 'PostAdd',
      },
      {
        path: '/post/:menuUid/:mentoUid/:mentoName/add',
        component: () => import('@/views/sub/board/form.vue'),
        name: 'PostAdd',
      },
      {
        path: '/post/:menuUid/:postUid/update',
        component: () => import('@/views/sub/board/form.vue'),
        name: 'PostUpdate',
      },
      {
        path: '/post/:menuUid/:postUid',
        component: () => import('@/views/sub/board/detail.vue'),
        name: 'PostDetail',
      },
      {
        path: '/makerspace/:menuUid',
        component: () => import('@/views/sub/makerspace/index.vue'),
        name: 'Makerspace',
      },
      {
        path: '/makerspace/:menuUid/:nickname',
        component: () => import('@/views/sub/makerspace/detail.vue'),
        name: 'MakerspaceDetail',
      },
      {
        path: '/startup/:menuUid',
        component: () => import('@/views/sub/startup/index.vue'),
        name: 'Startup',
      },
      {
        path: '/startup/:menuUid/:nickname',
        component: () => import('@/views/sub/startup/detail.vue'),
        name: 'StartupDetail',
      },
      {
        path: '/map/:menuUid',
        component: () => import('@/views/sub/map/index.vue'),
        name: 'MapView',
      },
      {
        path: '/support/:menuUid',
        component: () => import('@/views/sub/support/index.vue'),
        name: 'Support',
      },
      {
        path: '/support/:menuUid/:uid',
        component: () => import('@/views/sub/support/detail.vue'),
        name: 'SupportDetail',
      },
      {
        path: '/business/:menuUid',
        component: () => import('@/views/sub/business/index.vue'),
        name: 'Business',
      },
      {
        path: '/business/:menuUid/form',
        component: () => import('@/views/sub/business/form.vue'),
        name: 'BusinessAdd',
      },
      {
        path: '/business/:menuUid/:businessIdx/form',
        component: () => import('@/views/sub/business/form.vue'),
        name: 'BusinessUpdate',
      },
      {
        path: '/business/:menuUid/:businessIdx/detail',
        component: () => import('@/views/sub/business/detail.vue'),
        name: 'BusinessDetail',
      },
      {
        path: '/business/:menuUid/:businessIdx/apply',
        component: () => import('@/views/sub/business/apply.vue'),
        name: 'BusinessApply',
      },
      {
        path: '/invest/:menuUid',
        component: () => import('@/views/sub/invest/index.vue'),
        name: 'Invest',
      },
      {
        path: '/invest/:menuUid/:uid',
        component: () => import('@/views/sub/support/detail.vue'),
        name: 'InvestDetail',
      },
      {
        path: '/product/:menuUid',
        component: () => import('@/views/sub/product/index.vue'),
        name: 'Product',
      },
      {
        path: '/viewer',
        component: () => import('@/views/viewer/index.vue'),
        name: 'ViewerDownload',
      },
      {
        path: '/terms',
        component: () => import('@/views/user/join-terms.vue'),
        name: 'JoinTerms',
      },
      {
        path: '/join/:roleCode',
        component: () => import('@/views/user/join.vue'),
        name: 'UserJoin',
      },
      {
        path: '/info',
        component: () => import('@/views/user/join.vue'),
        name: 'UserInfo',
      },
      {
        path: '/join-success',
        component: () => import('@/views/user/join-success.vue'),
        name: 'JoinSuccess',
      },
      {
        path: '/find-password',
        component: () => import('@/views/user/find-password.vue'),
        name: 'FindPassword',
      },
      {
        path: '/login',
        component: () => import('@/views/user/login.vue'),
        name: 'Login',
      },
      {
        path: '/search',
        component: () => import('@/views/search/index.vue'),
        name: 'Search',
      },
      {
        path: '/event-api',
        component: () => import('@/views/document/event.vue'),
        name: 'EventApi',
      },
      {
        path: '/support-api',
        component: () => import('@/views/document/support.vue'),
        name: 'SupportApi',
      },
      {
        path: '/mypage',
        component: () => import('@/views/mypage/index.vue'),
        name: 'MyPage',
      },
    ],
  },
  {
    path: '/homepage',
    name: 'Homepage',
    component: HomepageLayout,
    children: [
      {
        path: '/:nickname',
        component: () => import('@/views/homepage/index.vue'),
        name: 'HomepageIndex',
      },
      {
        path: '/:nickname/setting',
        component: () => import('@/views/homepage/setting.vue'),
        name: 'HomepageSetting',
      },
      {
        path: '/:nickname/introduce',
        component: () => import('@/views/homepage/introduce.vue'),
        name: 'HomepageIntroduce',
      },
      {
        path: '/:nickname/introduce/setting',
        component: () => import('@/views/homepage/introduce-form.vue'),
        name: 'HomepageIntroduceSetting',
      },
      {
        path: '/:nickname/board/:boardUid',
        component: () => import('@/views/homepage/board.vue'),
        name: 'HomepageBoardIndex',
      },
      {
        path: '/:nickname/board/:boardUid/add',
        component: () => import('@/views/homepage/board-form.vue'),
        name: 'HomepagePostAdd',
      },
      {
        path: '/:nickname/board/:boardUid/:postUid/update',
        component: () => import('@/views/homepage/board-form.vue'),
        name: 'HomepagePostUpdate',
      },
      {
        path: '/:nickname/board/:boardUid/:postUid/reply',
        component: () => import('@/views/homepage/board-form.vue'),
        name: 'HomepagePostReply',
      },
      {
        path: '/:nickname/board/:boardUid/:postUid',
        component: () => import('@/views/homepage/post.vue'),
        name: 'HomepagePostDetail',
      },
      {
        path: '/:nickname/board/:boardUid/:postUid/password',
        component: () => import('@/views/homepage/board-password.vue'),
        name: 'HomepagePostPassword',
      },
      {
        path: '/:nickname/interest',
        component: () => import('@/views/homepage/interest.vue'),
        name: 'HomepageInterestPost',
      },
      {
        path: '/:nickname/interest/setting',
        component: () => import('@/views/homepage/interest-setting.vue'),
        name: 'HomepageInterestSetting',
      },
    ],
  },
  /*
  {
    path: '/video/:fileName',
    component: () => import('@/views/video/index.vue'),
    name: 'VideoView',
  },
  */
  {
    path: '/nice/accept',
    component: () => import('@/views/nice/accept.vue'),
    name: 'NiceAccept',
  },
  {
    path: '/nice/deny',
    component: () => import('@/views/nice/deny.vue'),
    name: 'NiceDeny',
  },
  {
    path: '/app',
    component: AppLayout,
    name: 'App',
    redirect: '/app/main',
    children: [
      {
        path: 'main',
        redirect: '/',
        component: () => import('@/views/app/index.vue'),
        name: 'AppMain',
      },
      {
        path: 'support',
        component: () => import('@/views/app/support/index.vue'),
        name: 'AppSupportList',
      },
      {
        path: 'support/detail/:postUid',
        component: () => import('@/views/app/support/detail.vue'),
        name: 'AppSupportDetail',
      },
      {
        path: 'support/detail/:postUid',
        component: () => import('@/views/app/support/apply.vue'),
        name: 'AppSupportApply',
      },
      {
        path: 'event',
        component: () => import('@/views/app/event/index.vue'),
        name: 'AppEventList',
      },
      {
        path: 'event/detail/:postUid',
        component: () => import('@/views/app/event/detail.vue'),
        name: 'AppEventDetail',
      },
      {
        path: 'notice/detail/:postUid',
        component: () => import('@/views/app/notice/detail.vue'),
        name: 'AppNoticeDetail',
      },
      {
        path: 'agency/:menuUid',
        component: () => import('@/views/sub/support/index.vue'),
        name: 'AppAgency',
      },
      {
        path: 'makerspace/:menuUid',
        component: () => import('@/views/sub/makerspace/index.vue'),
        name: 'AppMakerspace',
      },
      {
        path: 'map/:menuUid',
        component: () => import('@/views/sub/map/index.vue'),
        name: 'AppMapView',
      },
      {
        path: 'post/list/:menuUid',
        component: () => import('@/views/sub/board/index.vue'),
        name: 'AppBoard',
      },
      {
        path: 'post/write/:menuUid',
        component: () => import('@/views/sub/board/form.vue'),
        name: 'AppPostAdd',
      },
      {
        path: 'post/update/:menuUid/:postUid',
        component: () => import('@/views/sub/board/form.vue'),
        name: 'AppPostUpdate',
      },
      {
        path: 'post/detail/:menuUid/:postUid',
        component: () => import('@/views/sub/board/detail.vue'),
        name: 'AppPostDetail',
      },
      {
        path: 'contents/:menuUid',
        component: () => import('@/views/sub/contents.vue'),
        name: 'AppContents',
      },
      {
        path: 'mypage',
        component: () => import('@/views/app/mypage.vue'),
        name: 'AppMypage',
      },
      {
        path: 'login',
        component: () => import('@/views/user/login.vue'),
        name: 'AppLogin',
      },
      {
        path: 'join-terms',
        component: () => import('@/views/app/join-terms.vue'),
        name: 'AppJoinTerms',
      },
      {
        path: 'join/:roleCode',
        component: () => import('@/views/app/join.vue'),
        name: 'AppUserJoin',
      },
      {
        path: 'findpw',
        component: () => import('@/views/app/find-password.vue'),
        name: 'AppFindPassword',
      },
      {
        path: 'push-alarm',
        component: () => import('@/views/app/alarm/index.vue'),
        name: 'AppAlarm',
      },
      {
        path: 'equipment',
        component: () => import('@/views/app/equipment/index.vue'),
        name: 'AppEquipment',
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to: Route, from: Route, next: any) => {
  // Determine whether the user has logged in
  if (document.referrer) {
    /* eslint-disable */
    addReferrerHistory(document.referrer).then(() => {});
    /* eslint-enable */
  }
  if (UserModule.token) {
    await UserModule.GetUserInfo();
    await NiceModule.NiceAccept({ name: '', phone: '' });
    await UserModule.CheckToken();
    next();
  } else {
    next();
  }
});

router.afterEach((to: Route, from: Route) => {
  if (!getVisitKey()) {
    addVisit();
  }
});

export default router;
