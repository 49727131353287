
import { Vue, Component } from 'vue-property-decorator';
import { getPopupList } from '@/api/popup';
import { IPopup } from '@/types';
import cookies from 'js-cookie';

@Component({
  name: 'MainHeader',
})
export default class extends Vue {
  mounted() {
    getPopupList().then((res) => {
      res.data.forEach((p: IPopup) => {
        if (!cookies.get(p.uid)) this.popupList.push(p);
      });
    });
  }

  private popupList: IPopup[] = [];

  get filePath() {
    return `${process.env.VUE_APP_COMMON_API}/attached-file`;
  }

  private handleClosePopup(popupUid: string) {
    const popupRef = `popup-${popupUid}`;
    (this.$refs[popupRef] as any)[0].hidden = true;
  }

  private handleClickDisable(popupUid: string) {
    this.handleClosePopup(popupUid);
    cookies.set(popupUid, '1', { expires: 1 });
  }
}
