import {
  VuexModule,
  Module,
  Action,
  Mutation,
  getModule,
} from 'vuex-module-decorators';
import store from '@/store';

export interface INiceState {
  name: string
  phone: string
}

@Module({ dynamic: true, store, name: 'nice' })
class Nice extends VuexModule implements INiceState {
  public name = ''

  public phone = ''

  @Mutation
  private SET_NAME(name: string) {
    this.name = name;
  }

  @Mutation
  private SET_PHONE(phone: string) {
    this.phone = phone;
  }

  @Action
  public async NiceAccept(niceInfo: { name: string, phone: string }) {
    return new Promise((resolve) => {
      this.SET_NAME(niceInfo.name);
      this.SET_PHONE(niceInfo.phone);
      resolve();
    });
  }
}

export const NiceModule = getModule(Nice);
