
import {
  Vue,
  Component,
  Prop,
  Watch,
} from 'vue-property-decorator';
import {
  getBlogInfo,
  getBlogPostList,
  getBlogVisit,
  getBlogVisitCount,
} from '@/api/myBlog';
import { IBlog, IMyHomepageVisit, IPost } from '@/types';
import { getInterestBoardUid } from '@/api/board';
import moment from 'moment';
import Pagination from '@/components/board/pagination.vue';
import { getInterestBoardMenuUidList } from '@/api/myHomepageInterest';
import { getMainViewIds } from '@/api/menu';
import { UserModule } from '@/store/user';

@Component({
  name: 'MyBlog',
  components: {
    Pagination,
  },
})
export default class extends Vue {
  @Prop({ required: true }) private nickName!: string

  @Watch('blogNickName')
  private handleChangeBlogNickName() {
    console.log(this.nickName);
    this.init();
  }

  mounted() {
    this.blogNickName = this.nickName;
  }

  get imageUrl() {
    return `${process.env.VUE_APP_COMMON_API}/attached-file`;
  }

  get isOwner() {
    return UserModule.userId === this.blogInfo.userId || UserModule.roles.indexOf('ROLE_ADMIN') > -1;
  }

  private blogNickName = '';

  private interestBoardList: any[] = [];

  private blogInfo: IBlog = {
    userId: '',
    nickName: '',
    email: '',
    phone: '',
    companyName: '',
    companyTel: '',
    homepage: '',
    logoFileUid: '',
    manager: '',
    introduce: '',
  };

  private supportListQuery = {
    page: 0,
    size: 5,
  };

  private supportListTotal = 0;

  private supportTotalPages = 0;

  private eventListQuery = {
    page: 0,
    size: 4,
  };

  private eventListTotal = 0;

  private eventTotalPages = 0;

  private blogVisitList: IMyHomepageVisit[] = [];

  private boardUidList: string[] = [];

  private supportPostList: IPost[] = [];

  private eventPostList: IPost[] = [];

  private mainViewList: any[] = [];

  private visitCount = {
    todayCount: 0,
    totalCount: 0,
  };

  private init() {
    this.getInterestBoardList();
    getMainViewIds().then((res) => {
      this.mainViewList = res.data;
    });
    getBlogInfo(this.blogNickName).then((res) => {
      console.log(res);
      this.blogInfo = res.data;
    });
    getBlogVisit(this.blogNickName).then((res) => {
      this.blogVisitList = res.data.content;
    });
    getBlogVisitCount(this.blogNickName).then((res) => {
      this.visitCount = res.data;
    });
    getInterestBoardUid().then((res) => {
      this.boardUidList = res.data;
      this.getSupportPostList();
      this.getEventPostList();
    });
  }

  private getInterestBoardList() {
    getInterestBoardMenuUidList().then((res) => {
      this.interestBoardList = res.data;
    });
  }

  private isEnd(post: IPost) {
    if (!post.dataList[3] || !post.dataList[4]) return false;
    const today = moment();
    const startDay = moment(post.dataList[3].inputValue, 'YYYY-MM-DD');
    const endDay = moment(post.dataList[4].inputValue, 'YYYY-MM-DD').hours(23).minutes(59).seconds(59);
    return today.isSameOrAfter(startDay) && today.isSameOrBefore(endDay);
  }

  private handleClickClose() {
    this.$emit('close');
  }

  private handleChangePage(page: number, type: string) {
    if (type === 'support') {
      this.supportListQuery.page = page;
      this.getSupportPostList();
    } else if (type === 'event') {
      this.eventListQuery.page = page;
      this.getEventPostList();
    }
  }

  private getSupportPostList() {
    getBlogPostList(this.blogNickName, this.boardUidList[0], this.supportListQuery).then((res) => {
      this.supportPostList = res.data.content;
      this.supportListTotal = res.data.totalElements;
      this.supportTotalPages = res.data.totalPages;
    });
  }

  private getEventPostList() {
    getBlogPostList(this.blogNickName, this.boardUidList[1], this.eventListQuery).then((res) => {
      this.eventPostList = res.data.content;
      this.eventListTotal = res.data.totalElements;
      this.eventTotalPages = res.data.totalPages;
    });
  }

  private getPostRouter(post: IPost) {
    const menuFindIndex = this.interestBoardList.findIndex((b) => b.boardUid === post.boardUid);
    const interestMenuUid = this.interestBoardList[menuFindIndex].menuUid;
    if (this.$route.name !== 'AppAgency') {
      return {
        name: 'PostDetail',
        params: {
          menuUid: interestMenuUid,
          postUid: post.uid,
        },
      };
    }
    return {
      name: 'AppEventDetail',
      params: {
        postUid: post.uid,
      },
    };
  }

  private handleClickVisit(visit: IMyHomepageVisit) {
    if (visit.user) {
      const routeData = this.$router.resolve({ name: 'HomepageIndex', params: { nickname: visit.nickName } });
      window.open(routeData.href, '_blank');
    } else {
      this.blogNickName = visit.nickName;
    }
  }
}
