
import {
  Vue,
  Component,
} from 'vue-property-decorator';
import { UserModule } from '@/store/user';
import { deleteFcmToken } from '@/api/fcm-token';
import { FcmTokenModule } from '@/store/fcm-token';

@Component({
  name: 'AppFooter',
})
export default class extends Vue {
  get token() {
    return UserModule.token;
  }

  get routerName() {
    return this.$route.name;
  }

  private handleClickLogout() {
    UserModule.LogOut();
    deleteFcmToken(FcmTokenModule.fcmToken).then(() => {
      // nothing
    });
    this.$router.go(0);
  }
}
