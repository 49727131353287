
import {
  Vue,
  Component,
  Prop,
  Watch,
} from 'vue-property-decorator';

import $ from 'jquery';
import Slick from 'vue-slick';
import { getSearchDataList } from '@/api/search';
import {
  IBanner,
  IMenuDetail,
  IMenuMainView,
  IMyHomepageList,
  // IBoard,
  IPost,
  ISearchData,
} from '@/types';
import { getPostList } from '@/api/post';
import { gsap } from 'gsap';

import { getMainViewIds, getMainMenuList } from '@/api/menu';
import { getBannerList } from '@/api/banner';
import { getMainList } from '@/api/myHomepage';
import moment from 'moment';
import { getBusinessList } from '@/api/business';

@Component({
  name: 'MainBody',
  components: {
    Slick,
  },
})
export default class extends Vue {
  @Prop({ required: true }) private menuList!: IMenuDetail[];

  @Prop({ required: true }) private mainViewIdList!: IMenuMainView[];

  mounted() {
    gsap.registerPlugin(gsap);

    gsap.from('.search',
      {
        duration: 1,
        opacity: 0,
        scale: 0,
        y: 500,
        ease: 'power1',
        stagger: 0.1,
      });
    window.addEventListener('scroll', (): void => {
      const scrollTop: number = document.documentElement.scrollTop + 500 || document.body.scrollTop + 500;
      const menu: NodeListOf<Element> = document.querySelectorAll('.fullpage__menu');
      const menuItems: NodeListOf<Element> = document.querySelectorAll('.fullpage__menu-item');

      const section01: HTMLElement | null = document.getElementById('section01');
      const sectionNew: HTMLElement | null = document.getElementById('section__new');
      const section02: HTMLElement | null = document.getElementById('section02');
      const section03: HTMLElement | null = document.getElementById('section03');
      const section04: HTMLElement | null = document.getElementById('section04');
      const section05: HTMLElement | null = document.getElementById('section05');
      const section06: HTMLElement | null = document.getElementById('section06');

      if (scrollTop >= (section01?.offsetTop || 0) && scrollTop < (sectionNew?.offsetTop || 0)) {
        menuItems.forEach((item: Element) => item.classList.remove('active'));
        menuItems[0].classList.add('active');
        menu[0].classList.remove('black');
      } else if (scrollTop >= (sectionNew?.offsetTop || 0) && scrollTop < (section02?.offsetTop || 0)) {
        menuItems.forEach((item: Element) => item.classList.remove('active'));
        menuItems[1].classList.add('active');
        menu[0].classList.add('black');
      } else if (scrollTop >= (section02?.offsetTop || 0) && scrollTop < (section03?.offsetTop || 0)) {
        menuItems.forEach((item: Element) => item.classList.remove('active'));
        menuItems[2].classList.add('active');
        menu[0].classList.add('black');
      } else if (scrollTop >= (section03?.offsetTop || 0) && scrollTop < (section04?.offsetTop || 0)) {
        menuItems.forEach((item: Element) => item.classList.remove('active'));
        menuItems[3].classList.add('active');
        menu[0].classList.add('black');
      } else if (scrollTop >= (section04?.offsetTop || 0) && scrollTop < (section05?.offsetTop || 0)) {
        menuItems.forEach((item: Element) => item.classList.remove('active'));
        menuItems[4].classList.add('active');
        menu[0].classList.add('black');
      } else if (scrollTop >= (section05?.offsetTop || 0) && scrollTop < (section06?.offsetTop || 0)) {
        menuItems.forEach((item: Element) => item.classList.remove('active'));
        menuItems[5].classList.add('active');
        menu[0].classList.add('black');
      }
    });
    getSearchDataList().then((res) => {
      this.searchDataList = res.data;
    });
  }

  private tl1 = gsap.timeline({
    scrollTrigger: {
      trigger: '',
    },
  });

  private slickLoad = false;

  private newTab = '사업공고';

  private applyTab = '시설·공간';

  private eventTab = 1;

  private searchValue = '';

  private searchDataList: ISearchData[] = [];

  private supportPostList: IPost[] = [];

  private allList: any = [];

  private businessList: any = [];

  private businessListNew: any = [];

  private eventPostList: IPost[] = [];

  private noticePostList: IPost[] = [];

  private interviewPostList: IPost[] = [];

  private bannerList: IBanner[] = [];

  private mainHomepageList: IMyHomepageList[] = [];

  private mainMenuList: IMenuDetail[] = [];

  private activeFullpageMenu = '';

  private eventLength = 0;

  @Watch('mainViewIdList')
  private async handleChangeMenuList() {
    const loading = this.$loading.show();
    await this.init();
    await loading.hide();
  }

  private scrollTo(num: string) {
    const sectionId = `section${num}`;
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }

    this.activeFullpageMenu = num;
  }

  get imageUrl() {
    return `${process.env.VUE_APP_COMMON_API}/attached-file`;
  }

  private getImageBaseUrl() {
    return `${process.env.VUE_APP_COMMON_API}/attached-file`;
  }

  private handleNewTab(str: string) {
    switch (str) {
      case '사업공고':
        this.newTab = '사업공고';
        this.handleClickSlick('newSlick1', 'prev');
        break;

      case '행사정보':
        this.newTab = '행사정보';
        this.handleClickSlick('newSlick2', 'prev');
        break;

      case '창업뉴스':
        this.newTab = '창업뉴스';
        this.handleClickSlick('newSlick3', 'prev');
        break;

      default:
        break;
    }
  }

  private handleClickSlick(refName: string, type: string) {
    if (this.$refs[refName]) {
      const reference: any = this.$refs[refName];
      if (type === 'prev') reference.prev();
      if (type === 'next') reference.next();
    }
  }

  private getRouter(menu: any) {
    const router = {
      name: '',
      params: { menuUid: menu.uid },
    };
    if (menu.menuType === 'BOARD') router.name = 'BoardIndex';
    if (menu.menuType === 'CONTENTS') router.name = 'ContentsIndex';
    if (menu.menuType === 'MODULE') {
      router.name = menu.moduleUid;
    }
    return router;
  }

  private handleClickCategory(type: string) {
    this.applyTab = type;
    if (type === '전체') {
      this.selectedCategoryUid = '654f393f-c605-4ed7-90bc-c6c8affbe32d,327a26be-6861-4570-a4ad-54b4a9ba00ad,d97aa36c-41ba-40ba-86e2-228fdd6e80fb,224e5ccf-fcd8-4395-840f-868e63ab41f6,ee204210-11b3-4703-8fbf-95bcfccf2856,4e48e473-2792-4483-91ab-9c1ed1000be5';
    } else if (type === '시설·공간') {
      this.selectedCategoryUid = '327a26be-6861-4570-a4ad-54b4a9ba00ad';
    } else if (type === '창업교육') {
      this.selectedCategoryUid = 'd97aa36c-41ba-40ba-86e2-228fdd6e80fb';
    } else if (type === '사업화') {
      this.selectedCategoryUid = '224e5ccf-fcd8-4395-840f-868e63ab41f6';
    } else if (type === '자금') {
      this.selectedCategoryUid = '4e48e473-2792-4483-91ab-9c1ed1000be5';
    } else if (type === '기타') {
      this.selectedCategoryUid = 'ee204210-11b3-4703-8fbf-95bcfccf2856';
    }
    this.getBusinessNewList();
  }
  // 전체
  // private selectedCategoryUid = '654f393f-c605-4ed7-90bc-c6c8affbe32d,327a26be-6861-4570-a4ad-54b4a9ba00ad,d97aa36c-41ba-40ba-86e2-228fdd6e80fb,224e5ccf-fcd8-4395-840f-868e63ab41f6,ee204210-11b3-4703-8fbf-95bcfccf2856,4e48e473-2792-4483-91ab-9c1ed1000be5';\

  private selectedCategoryUid = '327a26be-6861-4570-a4ad-54b4a9ba00ad';

  private getBusinessNewList() {
    getBusinessList({
      page: 0,
      size: 10,
      searchValue: '',
      sort: 'progress',
      progress: 0,
      categories: this.selectedCategoryUid,
      startDate: '',
      endDate: '',
    }).then((res) => {
      let data = [...this.businessListNew];
      data = data.splice(0, 0);
      this.businessListNew = data.concat(res.data.content);
    });
  }

  private async init() {
    this.getBusinessNewList();
    getBannerList().then((res) => {
      this.bannerList = res.data;
    });
    getBusinessList({
      page: 0,
      // size: 4,
      searchValue: '',
      sort: 'progress',
      progress: 0,
      categories:
        ' 654f393f-c605-4ed7-90bc-c6c8affbe32d,327a26be-6861-4570-a4ad-54b4a9ba00ad,d97aa36c-41ba-40ba-86e2-228fdd6e80fb,224e5ccf-fcd8-4395-840f-868e63ab41f6,ee204210-11b3-4703-8fbf-95bcfccf2856,4e48e473-2792-4483-91ab-9c1ed1000be5',
      categories2: '40d51458-44e6-4f3b-91db-732941ac2571',
      startDate: '',
      endDate: '',
    }).then((res) => {
      this.businessList = res.data.content;
    });
    const boardIdList: string[] = [];

    await this.mainViewIdList.forEach((item) => {
      if (item.boardUid) {
        if (item.boardUid === '5ad93161-0db7-4cf1-af8c-9e19f83b8abc') {
          getPostList({ boardUid: item.boardUid, size: 4 }).then((res) => {
            this.noticePostList = res.data.content;
          });
        }
        /*
        if (item.boardUid === 'dc1e2055-0600-42b6-800b-904b18dfcdab') {
          getPostList({ boardUid: item.boardUid, size: 4 }).then((res) => { v
            this.supportPostList = res.data.content;
          });
        }
        */
        if (item.boardUid === '3e443ca9-92ff-4d50-877b-64f90c4d2c8a') {
          getPostList({ boardUid: item.boardUid, searchType: 'all' }).then((res) => {
            this.eventPostList = res.data.content;
            this.eventLength = this.eventPostList.length;
          });
        }
        if (item.boardUid === '1f7c38c3-6b2b-4f65-a46e-a469bbdb78bc') {
          getPostList({ boardUid: item.boardUid, size: 10 }).then((res) => {
            this.interviewPostList = res.data.content;
          });
        }
      }
    });
    getMainList().then((res) => {
      this.mainHomepageList = res.data;
    });
  }

  private handleEventClick(category: string) {
    const boardUid = '3e443ca9-92ff-4d50-877b-64f90c4d2c8a';
    getPostList({ boardUid, size: 5, categoryList: category }).then((res) => {
      this.eventPostList = res.data.content;
      let data = [...this.eventPostList];
      data = data.splice(0, 0);
      this.eventPostList = data.concat(res.data.content);
      this.eventLength = this.eventPostList.length;
    });
  }

  private getThumnail(thumbnail: string) {
    if (thumbnail.startsWith('http')) return thumbnail;
    return `${this.imageUrl}/${thumbnail}`;
  }

  private handleSearch() {
    if (this.searchValue.trim()) {
      this.$router.push({
        name: 'Search',
        query: { searchValue: this.searchValue },
      });
    } else {
      alert('검색어를 입력해주세요.');
    }
  }

  private tick() {
    $('.popular_inner li:first').slideUp(() => {
      $('.popular_inner li:first').appendTo($('.popular_inner ul')).slideDown();
    });
  }

  private handleClickNavi(type: string) {
    console.log(type);
    if (type === 'prev') {
      $('.popular_inner li:last')
        .hide()
        .prependTo($('.popular_inner ul'))
        .slideDown();
    }
    if (type === 'next') {
      $('.popular_inner li:first').slideUp(() => {
        $('.popular_inner li:first').detach().appendTo('.popular_inner ul');
      });
    }
  }

  private isEnd(post: IPost) {
    if (!post.dataList[3].inputValue || !post.dataList[4].inputValue) {
      return '진행중';
    }
    const today = moment().startOf('day');
    const endDay = moment(post.dataList[4].inputValue, 'YYYY-MM-DD');
    if (today.isSameOrBefore(endDay)) {
      const remain = endDay.diff(today, 'days');
      if (remain >= 1) return `마감 ${remain} 일전`;
      if (remain === 0) return '오늘 마감';
      return '오늘 마감';
    }
    return '마감';
  }

  private isEndClass(post: IPost) {
    if (!post.dataList[4].inputValue) return 'state01';
    const today = moment().startOf('day');
    const endDay = moment(post.dataList[4].inputValue, 'YYYY-MM-DD');
    const remain = endDay.diff(today, 'days');
    if (remain >= 30) return 'state03';
    if (remain >= 7) return 'state02';
    if (remain >= 0) return 'state01';
    return 'state00';
  }

  private getProgessClass(business: any) {
    if (business.dueDate >= 30) return 'state03';
    if (business.dueDate >= 7) return 'state02';
    if (business.dueDate >= 0) return 'state01';
    return 'state00';
  }

  private isEndBusiness(dueDate: number) {
    if (dueDate >= 1) return `D - ${dueDate}`;
    if (dueDate === 0) return '오늘 마감';
    return '마감';
  }

  private formatDate(dateString: string): string {
    const date = new Date(dateString);
    const year = date.getFullYear().toString().substr(-2); // 년도의 마지막 두 자리
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 월 (0부터 시작하므로 +1 필요)
    const day = date.getDate().toString().padStart(2, '0'); // 일

    return `${year}-${month}-${day}`;
  }

  private siteSlickOptions = {
    autoplay: true,
    autoplaySpeed: 5000,
    dots: true,
    slidesToShow: 5,
    slidesToScroll: 5,
    infinite: true,
    speed: 500,
    arrows: true,
    pauseOnHover: true,
    prevArrow: $('.rel-prev'),
    nextArrow: $('.rel-next'),
    draggable: true,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  private siteSlickOptions2 = {
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: 'linear',
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    speed: 1500,
    arrows: true,
    pauseOnHover: true,
    prevArrow: $('.rel-prev'),
    nextArrow: $('.rel-next'),
    draggable: true,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  private newSlickOptions = {
    rows: 2,
    autoplay: true,
    arrows: false,
    dots: false,
    autoplaySpeed: 4000,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    draggable: true,
    infinite: true,
    focusOnSelect: true,
    pauseOnHover: true,
    // prevArrow: $('.just-prev'),
    // nextArrow: $('.just-next'),
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 501,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 0,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  private applySlickOptions = {
    rows: 2,
    autoplay: true,
    arrows: true,
    dots: false,
    autoplaySpeed: 4000,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    draggable: true,
    infinite: true,
    focusOnSelect: true,
    pauseOnHover: true,
    prevArrow: $('.apply-prev'),
    nextArrow: $('.apply-next'),
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 501,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 0,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  private stnewsSlickOptions = {
    autoplay: true,
    arrows: true,
    dots: false,
    autoplaySpeed: 4000,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    draggable: true,
    mobileFirst: true,
    infinite: true,
    focusOnSelect: true,
    pauseOnHover: true,
    prevArrow: $('.stnews-prev'),
    nextArrow: $('.stnews-next'),
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 501,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 0,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  private startupSlickOptions = {
    autoplay: true,
    arrows: true,
    dots: false,
    autoplaySpeed: 4000,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    draggable: true,
    mobileFirst: true,
    infinite: true,
    focusOnSelect: true,
    pauseOnHover: true,
    prevArrow: $('.startup-prev'),
    nextArrow: $('.startup-next'),
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 501,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 0,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  private popupSlickOptions = {
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: 'linear',
    infinite: true,
    speed: 500,
    arrows: true,
    draggable: true,
    prevArrow: $('.ban-prev'),
    nextArrow: $('.ban-next'),
  };
}
