
import { Vue, Component } from 'vue-property-decorator';
import { getMainViewIds, getMenuList } from '@/api/menu';
import PopupView from '@/components/popup/index.vue';
import MyBlog from '@/components/blog/index.vue';
import {
  MainHeader,
  MainBody,
  MainFooter,
} from './components/main';

@Component({
  name: 'Main',
  components: {
    MainHeader,
    MainBody,
    MainFooter,
    PopupView,
    MyBlog,
  },
})
export default class extends Vue {
  mounted() {
    this.getMenuList();
  }

  private menuList = [];

  private mainViewIdList: any[] = [];

  private myBlogVisible = false;

  private getMenuList() {
    const load = this.$loading.show();
    getMenuList().then((res) => {
      this.menuList = res.data;
      this.$nextTick(() => {
        load.hide();
      });
    }).catch(() => {
      load.hide();
    });
    getMainViewIds().then((res) => {
      this.mainViewIdList = res.data;
    });
  }

  private handleClickBlog() {
    this.myBlogVisible = !this.myBlogVisible;
  }
}
