import request from '@/utils/request';

const PATH = '/referrer-history';

export const addReferrerHistory = (referrer: string) => request({
  url: PATH,
  method: 'post',
  params: {
    url: referrer,
  },
});
