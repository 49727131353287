import { IMyHompageIntroduceSave } from '@/types';
import request from '@/utils/request';

const PATH = '/my-homepage/interest';

export const getInterestPostList = (nickname: string, listQuery: any) => request({
  url: `${PATH}/${nickname}`,
  method: 'get',
  params: {
    ...listQuery,
  },
});

export const getInterestBoardMenuUidList = () => request({
  url: `${PATH}/menu`,
  method: 'get',
});
