import moment from 'moment';

export const parseDate = (stringDate: string, format: string) => {
  if (!stringDate) return '-';
  const parsingDate = moment(stringDate).format(format || 'YYYY.MM.DD');
  return parsingDate;
};

export const parseDateYear = (stringDate: string, format: string) => {
  const parsingDate = moment(stringDate).format(format || 'YYYY');
  return parsingDate;
};

export const parseDateMonth = (stringDate: string, format: string) => {
  const parsingDate = moment(stringDate).format(format || 'MM');
  return parsingDate;
};

export const parseDateDay = (stringDate: string, format: string) => {
  const parsingDate = moment(stringDate).format(format || 'DD');
  return parsingDate;
};

export const parseDateTime = (stringDate: string, format: string) => {
  if (!stringDate) return '';
  const parsingDate = moment(stringDate).format(format || 'YYYY.MM.DD HH:mm');
  return parsingDate;
};

export const removeTag = (value: string) => {
  const filterValue2 = value.replace(/(<([^>]+)>)/ig, '').replace(/&nbsp;/gi, '').replace(/&#40;/gi, '(').replace(/&#41;/gi, ')');
  const filterValue = filterValue2.replace(/&quot;/gi, '"').replace(/&apos;/gi, '`');
  return filterValue;
};

export const numToString = (num: number | string) => {
  const numStr = num.toLocaleString('ko-KR');
  return numStr;
};

export const parseConcatNumber = (data: string) => {
  if (data) {
    const filterDate = data.replace(/[^0-9]/g, '').replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, '$1-$2-$3');
    return filterDate;
  }
  return data;
};
