
import { getMainViewIds, getMenuList } from '@/api/menu';
import {
  Vue,
  Component,
  Prop,
  Watch,
} from 'vue-property-decorator';
import {
  SubHeader,
  SubBody,
  SubFooter,
} from './components/sub';

@Component({
  name: 'SubLayout',
  components: {
    SubHeader,
    SubBody,
    SubFooter,
  },
})
export default class extends Vue {
  mounted() {
    this.getMenuList();
  }

  private menuList = [];

  private mainViewIdList: any[] = [];

  private getMenuList() {
    getMenuList().then((res) => {
      this.menuList = res.data;
    });
    getMainViewIds().then((res) => {
      this.mainViewIdList = res.data;
    });
  }
}
